<template>
	<div class="common-nav-bar">
		<van-nav-bar >
			<template #left>
				<van-icon name="arrow-left"  @click="goBack(1)" />
			</template>
			<template #title>
				<div class="title">
					{{title}}
				</div>
			</template>
		</van-nav-bar>
	</div>
</template>

<script>
	export default {
		name: "CommonNavBar",
		props: {
			title: {
				type: String,
				default: '标题'
			}
		},
		methods: {
			goBack(type) {
				switch (type) {
					case 1:
						this.$router.go(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.title {
		height: 20px;
		font-size: 18px;
		font-family: HarmonyOS Sans SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 18px;
	}

	/deep/ .van-nav-bar .van-icon {
		color: #fff;
		font-size: 18px;
	}

	.common-nav-bar {
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 999;
		left: 0;
		background: #36C9FF;
		color: #fff;

		.van-nav-bar {
			background: #36C9FF;
			color: #fff;
		}

		::deep .van-nav-bar__title {
			color: #fff;
		}
	}
</style>
