<template>
	<div class="container">
		<CommonNavBar title="消息中心"></CommonNavBar>
		<div class="top-nav-bar">
			<div class="filter-nav" id="filter-nav">
				<div style="width: 33%;" @click="searchClick(' ')">
					<span >全部消息</span>
				</div>
				<div style=" width: 33%;" @click="searchClick('1')" >
					<span class="agree">已通过</span>
				</div>
				<div style="width: 33%" @click="searchClick('2')">
					<span >已拒绝</span>
				</div>
			</div>
		</div>
		<div class="sroll">
			<van-list v-model="loading" @refresh="onRefresh" :finished="finished" @load="onLoad"
				:immediate-check="immediateCheck" offset="200">
				<div class="pro-content-list">
					<RecommendList v-if="customerList.length !== 0" :list="customerList" @check="checkCustomer" />
					<div v-if="showEmpty" class="product-item">
						<span>暂无数据</span>
					</div>
				</div>
			</van-list>
			<WaterMark style="padding-bottom: 80px" />
		</div>

		<van-overlay :show="showBang">
			<div class="flex-center" style="height: 100%;">
				<div class="wrapper">
					<div class="flex-center">
						<img src="@/assets/img-v3/new-customer/bang.png" class="bang-img" />
					</div>
					<div class="flex-center bang-text">确定要{{bangText ==1?'拒绝':'同意'}}吗?</div>
					<div class="flex-btw btn-foot">
						<div class="btn-cancel flex-center" @click="showBang = false">取消</div>
						<div class="btn-confirm flex-center" @click="update">确定</div>
					</div>
				</div>
			</div>
		</van-overlay>


	</div>
</template>
<script>
	import {
		listWarrant,
		updateUser
	} from "@/api";
	import {
		Popup,
		Picker,
		Toast,
		Dialog
	} from 'vant';
	import RecommendList from "./components/RecommendList.vue";
	import CommonNavBar from '@/components/common/CommonNavBar.vue';
	export default {
		name: "MessageList",
		components: {
			RecommendList,
			CommonNavBar
		},
		data() {
			return {
				showBang: false,
				tag: '',
				immediateCheck: false,
				customerList: [],
				showTag: false,
				sortName: "",
				pageSize: 10,
				page: 0,
				finished: false,
				loading: false,
				total: 0,
				showEmpty: false,
				user: '',
				lv: '' ,// 1 全部等级 2 外地客户（全部可见） 3 本地客户（全部不可见） 4 所有客户（扫码可见）
				bangText:'',
				state:'' //1 通过 2 拒绝
			};
		},
		mounted() {
			// this.onRefresh()
		},
		methods: {
			/**
			 * 
			 * */
			searchClick(nick){
				this.page = 0
				this.state = nick
				this.customerList = []
				this.onRefresh()
			},
			
			checkCustomer(type, textValue,item) {
				this.user = item;
				this.bangText = textValue;
				if (type === 'bang') { //拉黑
					this.showBang = true
				}
			},
			update() {
				updateUser({
					openid: this.user.wxOpenId,
					lv: this.bangText,
					shopUuid: this.$store.state.shopUuid
				}).then(res => {
					if (res.state == 100) {
						this.user.dataState = (this.bangText == 1?'2':'1');
						this.$forceUpdate()
						this.showBang = false
					}
				})
			},
			onCancel() {
				Toast('取消')
			},
			onRefresh() {
				// 清空列表数据
				this.customerList = [];
				this.page = 0;
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.onLoad();
			},
			getInfo(data) {
				this.$router.push({
					name: "ProductDetails",
					query: data,
				});
			},
			filterType() {
				this.showFilter = !this.showFilter;
				if (this.showFilter) {
					this.showSortBrowse = false;
					this.showNewProduct = false;
				}
			},

			onLoad() {
				this.showEmpty = false;
				const params = {
					page: this.page,
					size: this.pageSize,
					shopUuid: this.$store.state.shopUuid,
					state:this.state
				};

				listWarrant(params).then((res) => {
					if (res.state == 100) {
						if (res.items.records.length > 0) {
							res.items.records.map((resp) => {
								this.customerList.push(resp);
							});
						}
						if (this.customerList.length === 0) {
							this.showEmpty = true;
						}
						this.showFilter = false;
						this.total = res.items.total;
						// 加载状态结束
						this.loading = false;
						// 数据全部加载完成
						if (((this.page + 1) * this.pageSize) > this.total) {
							this.finished = true;
						} else {
							this.page = this.page + 1;
						}

					}
				});
			},
		},
	};
</script>

<style lang="less">
	.wrapper {
		width: 275px;
		height: 160px;
		background: #FFFFFF;
		border-radius: 10px;

		.btn-foot {
			padding: 28px 38px 13px 38px;

			.btn-cancel {
				width: 90px;
				height: 35px;
				background: #F0F0F0;
				border-radius: 18px;
				font-size: 16px;
				font-family: HarmonyOS Sans SC;
				font-weight: 400;
				color: #646464;
			}

			.btn-confirm {
				width: 90px;
				height: 35px;
				background: #36C9FF;
				border-radius: 18px;
				font-size: 16px;
				font-family: HarmonyOS Sans SC;
				font-weight: 400;
				color: #fff !important;
			}
		}

	}

	.bang-img {
		height: 39px;
		width: 39px;
		margin-top: 21px;
	}

	.bang-text {
		margin-top: 10px;
		font-size: 16px;
		font-family: HarmonyOS Sans SC;
		font-weight: 400;
		color: #000000;
	}

	.container {
		padding-top: 54px;

		.sroll {
			padding-top: 60px;
		}

		//解决底部出现大量空白，以及列表项显示不全的问题
		._v-container>._v-content>.pull-to-refresh-layer {
			width: 100%;
			height: 120px;
			margin-top: -120px;
			text-align: center;
			font-size: 32px;
			color: #aaa;
			float: left;
			top: 0;
			left: 0;
		}

		.text-cut {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.text-blue {
			color: #3d51ff;
		}

		.filter-nav {
			width: 100%;
			background-color: #ffffff;
			line-height: 40px;
			display: flex;
			justify-content: space-between;
			text-align: center;
			font-size: 15px;
			color: #505050;
			position: relative;
			.agree{
				padding: 0 30px 0 30px;
				border-left: 1px solid #eee;
				border-right: 1px solid #eee;
			}
		}

		.type-btn {
			width: 100%;
			background-color: #ffffff;
			padding: 5px;
			display: flex;
			flex-wrap: wrap;
			height: 40%;
			overflow-y: scroll;

			.btn {
				border-radius: 50px;
				line-height: 20px;
				padding: 6px 15px;
				margin: 4px 5px;
				color: #ffffff;
			}
		}

		&::before {
			content: "";
			display: table;
		}

		.top-nav-bar {
			position: fixed;
			top: 60px;
			width: 100%;
			z-index: 999;
			.panel {
				height: 100%;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.6);
				position: fixed;
				top: 88px;
			}
		}

		.pro-type {
			width: 100vw;
			overflow: hidden;
			position: fixed;
			top: 60px;
			z-index: 999;
		}

		.pro-content-list {
			width: 100vw;
			// height: 100%;
			// overflow-y: scroll;
			padding-bottom: 40px;
			.product-item {
				width: 361px;
				height: 150px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
				border-radius: 10px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20px auto;

				&>span {
					font-size: 24px;
					font-family: PingFang SC;
					font-weight: 700;
					color: #969696;
				}
			}
		}

		.content {
			.pro-content-list {
				padding-bottom: 30px;
			}
		}
	}
</style>
